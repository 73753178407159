.ngx-datatable {
    border: 1px solid $border-color;
    margin-top: 15px;
    &.bootstrap {
        .datatable-body {
            .datatable-body-row {
                padding: 0;
                border-width: 1px;
                border-style: solid solid dashed;
                border-color: transparent transparent $border-color;

                &.datatable-row-even {
                    background-color: transparent;
                }
                &.active {
                    background-color: transparent;
                    color: inherit;
                    border: 1px solid $brand-secondary;
                }
                .datatable-body-cell {
                    padding: 0.75rem;
                    &.centerAlign {
                        text-align: center;
                    }

                    .datatable-body-cell-label {
                        span {
                            &.ellipsis {
                                display: block;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 90%;
                            }
                        }
                    }
                }
            }
        }

        &.vertical-scroll {
            .datatable-body {
                max-height: 500px;
            }
        }

        .datatable-header .datatable-header-cell {
            border-bottom: 0;
        }
        .datatable-footer {
            background-color: $gray-lighter;
            color: $text-color;
        }
        .btn {
            padding: 8px 10px;
        }
        .input-group-btn {
            .btn {
                padding: 12px 10px;
            }
        }

        &.has-dropdown-cells {
            overflow: visible;

            .dropdown-cell {
                overflow: visible;
            }
        }
    }
    .datatable-body {
        .datatable-row-wrapper {
            &:last-child {
                .datatable-body-row:not(.active) {
                    border-bottom-color: transparent;
                }
            }
        }
    }
}

.datatable-header-cell-template-wrap {
    display: block;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
}

.datatable-header-cell {
    background-color: $gray-dark;
    padding: 1.25rem;
    font-size: 14px;
    font-weight: bold;
    color: $brand-text-white;
    min-height: 50px;
    max-height: 60px; // Two lines
    overflow: hidden;
}
