@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot?q8en3h');
  src: url('../../fonts/icomoon.eot?q8en3h#iefix') format('embedded-opentype'), url('../../fonts/icomoon.woff2?q8en3h') format('woff2'),
    url('../../fonts/icomoon.ttf?q8en3h') format('truetype'), url('../../fonts/icomoon.woff?q8en3h') format('woff'),
    url('../../fonts/icomoon.svg?q8en3h#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='fa-'],
[class*=' fa-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  position: relative;
  top: 1px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-fw {
  width: 1.28571429em;
  text-align: center;
}
.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.fa-li.fa-lg {
  left: -1.85714286em;
}
.fa-border {
  padding: 0.2em 0.25em 0.15em;
  border: solid 0.08em #eeeeee;
  border-radius: 0.1em;
}
.float-end {
  float: right;
}
.float-start {
  float: left;
}
.li.float-start {
  margin-right: 0.3em;
}
.li.float-end {
  margin-left: 0.3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fa-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  display: inline-block;
}
.fa-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  display: inline-block;
}
.fa-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  display: inline-block;
}
.fa-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}
.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #ffffff;
}

.fa-hat:before {
  content: '\e900';
}
.fa-500px:before {
  content: '\e901';
}
.fa-adjust:before {
  content: '\e902';
}
.fa-adn:before {
  content: '\e903';
}
.fa-align-center:before {
  content: '\e904';
}
.fa-align-justify:before {
  content: '\e905';
}
.fa-align-left:before {
  content: '\e906';
}
.fa-align-right:before {
  content: '\e907';
}
.fa-amazon:before {
  content: '\e908';
}
.fa-ambulance:before {
  content: '\e909';
}
.fa-anchor:before {
  content: '\e90a';
}
.fa-android:before {
  content: '\e90b';
}
.fa-angellist:before {
  content: '\e90c';
}
.fa-angle-double-down:before {
  content: '\e90d';
}
.fa-angle-double-left:before {
  content: '\e90e';
}
.fa-angle-double-right:before {
  content: '\e90f';
}
.fa-angle-double-up:before {
  content: '\e910';
}
.fa-angle-down:before {
  content: '\e911';
}
.fa-angle-left:before {
  content: '\e912';
}
.fa-angle-right:before {
  content: '\e913';
}
.fa-angle-up:before {
  content: '\e914';
}
.fa-apple:before {
  content: '\e915';
}
.fa-archive:before {
  content: '\e916';
}
.fa-area-chart:before {
  content: '\e917';
}
.fa-arrow-circle-down:before {
  content: '\e918';
}
.fa-arrow-circle-left:before {
  content: '\e919';
}
.fa-arrow-circle-o-down:before {
  content: '\e91a';
}
.fa-arrow-circle-o-left:before {
  content: '\e91b';
}
.fa-arrow-circle-o-right:before {
  content: '\e91c';
}
.fa-arrow-circle-o-up:before {
  content: '\e91d';
}
.fa-arrow-circle-right:before {
  content: '\e91e';
}
.fa-arrow-circle-up:before {
  content: '\e91f';
}
.fa-arrow-down:before {
  content: '\e920';
}
.fa-arrow-left:before {
  content: '\e921';
}
.fa-arrow-right:before {
  content: '\e922';
}
.fa-arrow-up:before {
  content: '\e923';
}
.fa-arrows-alt:before {
  content: '\e924';
}
.fa-arrows-h:before {
  content: '\e925';
}
.fa-arrows-v:before {
  content: '\e926';
}
.fa-arrows:before {
  content: '\e927';
}
.fa-asterisk:before {
  content: '\e928';
}
.fa-at:before {
  content: '\e929';
}
.fa-automobile:before {
  content: '\e92a';
}
.fa-backward:before {
  content: '\e92b';
}
.fa-balance-scale:before {
  content: '\e92c';
}
.fa-ban:before {
  content: '\e92d';
}
.fa-bank:before {
  content: '\e92e';
}
.fa-bar-chart-o:before {
  content: '\e92f';
}
.fa-bar-chart:before {
  content: '\e930';
}
.fa-barcode:before {
  content: '\e931';
}
.fa-bars:before {
  content: '\e932';
}
.fa-battery-0:before {
  content: '\e933';
}
.fa-battery-1:before {
  content: '\e934';
}
.fa-battery-2:before {
  content: '\e935';
}
.fa-battery-3:before {
  content: '\e936';
}
.fa-battery-4:before {
  content: '\e937';
}
.fa-battery-empty:before {
  content: '\e938';
}
.fa-battery-full:before {
  content: '\e939';
}
.fa-battery-half:before {
  content: '\e93a';
}
.fa-battery-quarter:before {
  content: '\e93b';
}
.fa-battery-three-quarters:before {
  content: '\e93c';
}
.fa-bed:before {
  content: '\e93d';
}
.fa-beer:before {
  content: '\e93e';
}
.fa-behance-square:before {
  content: '\e93f';
}
.fa-behance:before {
  content: '\e940';
}
.fa-bell-o:before {
  content: '\e941';
}
.fa-bell-slash-o:before {
  content: '\e942';
}
.fa-bell-slash:before {
  content: '\e943';
}
.fa-bell:before {
  content: '\e944';
}
.fa-bicycle:before {
  content: '\e945';
}
.fa-binoculars:before {
  content: '\e946';
}
.fa-birthday-cake:before {
  content: '\e947';
}
.fa-bitbucket-square:before {
  content: '\e948';
}
.fa-bitbucket:before {
  content: '\e949';
}
.fa-bitcoin:before {
  content: '\e94a';
}
.fa-black-tie:before {
  content: '\e94b';
}
.fa-bold:before {
  content: '\e94c';
}
.fa-bolt:before {
  content: '\e94d';
}
.fa-bomb:before {
  content: '\e94e';
}
.fa-book:before {
  content: '\e94f';
}
.fa-bookmark-o:before {
  content: '\e950';
}
.fa-bookmark:before {
  content: '\e951';
}
.fa-briefcase:before {
  content: '\e952';
}
.fa-btc:before {
  content: '\e953';
}
.fa-bug:before {
  content: '\e954';
}
.fa-building-o:before {
  content: '\e955';
}
.fa-building:before {
  content: '\e956';
}
.fa-bullhorn:before {
  content: '\e957';
}
.fa-bullseye:before {
  content: '\e958';
}
.fa-bus:before {
  content: '\e959';
}
.fa-buysellads:before {
  content: '\e95a';
}
.fa-cab:before {
  content: '\e95b';
}
.fa-calculator:before {
  content: '\e95c';
}
.fa-calendar-check-o:before {
  content: '\e95d';
}
.fa-calendar-minus-o:before {
  content: '\e95e';
}
.fa-calendar-o:before {
  content: '\e95f';
}
.fa-calendar-plus-o:before {
  content: '\e960';
}
.fa-calendar-times-o:before {
  content: '\e961';
}
.fa-calendar:before {
  content: '\e962';
}
.fa-camera-retro:before {
  content: '\e963';
}
.fa-camera:before {
  content: '\e964';
}
.fa-car:before {
  content: '\e965';
}
.fa-caret-down:before {
  content: '\e966';
}
.fa-caret-left:before {
  content: '\e967';
}
.fa-caret-right:before {
  content: '\e968';
}
.fa-caret-square-o-down:before {
  content: '\e969';
}
.fa-caret-square-o-left:before {
  content: '\e96a';
}
.fa-caret-square-o-right:before {
  content: '\e96b';
}
.fa-caret-square-o-up:before {
  content: '\e96c';
}
.fa-caret-up:before {
  content: '\e96d';
}
.fa-cart-arrow-down:before {
  content: '\e96e';
}
.fa-cart-plus:before {
  content: '\e96f';
}
.fa-cc-amex:before {
  content: '\e970';
}
.fa-cc-diners-club:before {
  content: '\e971';
}
.fa-cc-discover:before {
  content: '\e972';
}
.fa-cc-jcb:before {
  content: '\e973';
}
.fa-cc-mastercard:before {
  content: '\e974';
}
.fa-cc-paypal:before {
  content: '\e975';
}
.fa-cc-stripe:before {
  content: '\e976';
}
.fa-cc-visa:before {
  content: '\e977';
}
.fa-cc:before {
  content: '\e978';
}
.fa-certificate:before {
  content: '\e979';
}
.fa-chain-broken:before {
  content: '\e97a';
}
.fa-chain:before {
  content: '\e97b';
}
.fa-check-circle-o:before {
  content: '\e97c';
}
.fa-check-circle:before {
  content: '\e97d';
}
.fa-check-square-o:before {
  content: '\e97e';
}
.fa-check-square:before {
  content: '\e97f';
}
.fa-check:before {
  content: '\e980';
}
.fa-chevron-circle-down:before {
  content: '\e981';
}
.fa-chevron-circle-left:before {
  content: '\e982';
}
.fa-chevron-circle-right:before {
  content: '\e983';
}
.fa-chevron-circle-up:before {
  content: '\e984';
}
.fa-chevron-down:before {
  content: '\e985';
}
.fa-chevron-left:before {
  content: '\e986';
}
.fa-chevron-right:before {
  content: '\e987';
}
.fa-chevron-up:before {
  content: '\e988';
}
.fa-child:before {
  content: '\e989';
}
.fa-chrome:before {
  content: '\e98a';
}
.fa-circle-o-notch:before {
  content: '\e98b';
}
.fa-circle-o:before {
  content: '\e98c';
}
.fa-circle-thin:before {
  content: '\e98d';
}
.fa-circle:before {
  content: '\e98e';
}
.fa-clipboard:before {
  content: '\e98f';
}
.fa-clock-o:before {
  content: '\e990';
}
.fa-clone:before {
  content: '\e991';
}
.fa-close:before {
  content: '\e992';
}
.fa-cloud-download:before {
  content: '\e993';
}
.fa-cloud-upload:before {
  content: '\e994';
}
.fa-cloud:before {
  content: '\e995';
}
.fa-cny:before {
  content: '\e996';
}
.fa-code-fork:before {
  content: '\e997';
}
.fa-code:before {
  content: '\e998';
}
.fa-codepen:before {
  content: '\e999';
}
.fa-coffee:before {
  content: '\e99a';
}
.fa-cog:before {
  content: '\e99b';
}
.fa-cogs:before {
  content: '\e99c';
}
.fa-columns:before {
  content: '\e99d';
}
.fa-comment-o:before {
  content: '\e99e';
}
.fa-comment:before {
  content: '\e99f';
}
.fa-commenting-o:before {
  content: '\e9a0';
}
.fa-commenting:before {
  content: '\e9a1';
}
.fa-comments-o:before {
  content: '\e9a2';
}
.fa-comments:before {
  content: '\e9a3';
}
.fa-compass:before {
  content: '\e9a4';
}
.fa-compress:before {
  content: '\e9a5';
}
.fa-connectdevelop:before {
  content: '\e9a6';
}
.fa-contao:before {
  content: '\e9a7';
}
.fa-copy:before {
  content: '\e9a8';
}
.fa-copyright:before {
  content: '\e9a9';
}
.fa-creative-commons:before {
  content: '\e9aa';
}
.fa-credit-card:before {
  content: '\e9ab';
}
.fa-crop:before {
  content: '\e9ac';
}
.fa-crosshairs:before {
  content: '\e9ad';
}
.fa-css3:before {
  content: '\e9ae';
}
.fa-cube:before {
  content: '\e9af';
}
.fa-cubes:before {
  content: '\e9b0';
}
.fa-cut:before {
  content: '\e9b1';
}
.fa-cutlery:before {
  content: '\e9b2';
}
.fa-dashboard:before {
  content: '\e9b3';
}
.fa-dashcube:before {
  content: '\e9b4';
}
.fa-database:before {
  content: '\e9b5';
}
.fa-dedent:before {
  content: '\e9b6';
}
.fa-delicious:before {
  content: '\e9b7';
}
.fa-desktop:before {
  content: '\e9b8';
}
.fa-deviantart:before {
  content: '\e9b9';
}
.fa-diamond:before {
  content: '\e9ba';
}
.fa-digg:before {
  content: '\e9bb';
}
.fa-dollar:before {
  content: '\e9bc';
}
.fa-dot-circle-o:before {
  content: '\e9bd';
}
.fa-download:before {
  content: '\e9be';
}
.fa-dribbble:before {
  content: '\e9bf';
}
.fa-dropbox:before {
  content: '\e9c0';
}
.fa-drupal:before {
  content: '\e9c1';
}
.fa-edit:before {
  content: '\e9c2';
}
.fa-eject:before {
  content: '\e9c3';
}
.fa-ellipsis-h:before {
  content: '\e9c4';
}
.fa-ellipsis-v:before {
  content: '\e9c5';
}
.fa-empire:before {
  content: '\e9c6';
}
.fa-envelope-o:before {
  content: '\e9c7';
}
.fa-envelope-square:before {
  content: '\e9c8';
}
.fa-envelope:before {
  content: '\e9c9';
}
.fa-eraser:before {
  content: '\e9ca';
}
.fa-eur:before {
  content: '\e9cb';
}
.fa-euro:before {
  content: '\e9cc';
}
.fa-exchange:before {
  content: '\e9cd';
}
.fa-exclamation-circle:before {
  content: '\e9ce';
}
.fa-exclamation-triangle:before {
  content: '\e9cf';
}
.fa-exclamation:before {
  content: '\e9d0';
}
.fa-expand:before {
  content: '\e9d1';
}
.fa-expeditedssl:before {
  content: '\e9d2';
}
.fa-external-link-square:before {
  content: '\e9d3';
}
.fa-external-link:before {
  content: '\e9d4';
}
.fa-eye-slash:before {
  content: '\e9d5';
}
.fa-eye:before {
  content: '\e9d6';
}
.fa-eyedropper:before {
  content: '\e9d7';
}
.fa-facebook-official:before {
  content: '\e9d8';
}
.fa-facebook-square:before {
  content: '\e9d9';
}
.fa-facebook:before {
  content: '\e9da';
}
.fa-fast-backward:before {
  content: '\e9db';
}
.fa-fast-forward:before {
  content: '\e9dc';
}
.fa-fax:before {
  content: '\e9dd';
}
.fa-female:before {
  content: '\e9de';
}
.fa-fighter-jet:before {
  content: '\e9df';
}
.fa-file-archive-o:before {
  content: '\e9e0';
}
.fa-file-audio-o:before {
  content: '\e9e1';
}
.fa-file-code-o:before {
  content: '\e9e2';
}
.fa-file-excel-o:before {
  content: '\e9e3';
}
.fa-file-image-o:before {
  content: '\e9e4';
}
.fa-file-movie-o:before {
  content: '\e9e5';
}
.fa-file-o:before {
  content: '\e9e6';
}
.fa-file-pdf-o:before {
  content: '\e9e7';
}
.fa-file-photo-o:before {
  content: '\e9e8';
}
.fa-file-picture-o:before {
  content: '\e9e9';
}
.fa-file-powerpoint-o:before {
  content: '\e9ea';
}
.fa-file-sound-o:before {
  content: '\e9eb';
}
.fa-file-text-o:before {
  content: '\e9ec';
}
.fa-file-text:before {
  content: '\e9ed';
}
.fa-file-video-o:before {
  content: '\e9ee';
}
.fa-file-word-o:before {
  content: '\e9ef';
}
.fa-file-zip-o:before {
  content: '\e9f0';
}
.fa-file:before {
  content: '\e9f1';
}
.fa-files-o:before {
  content: '\e9f2';
}
.fa-film:before {
  content: '\e9f3';
}
.fa-filter:before {
  content: '\e9f4';
}
.fa-fire-extinguisher:before {
  content: '\e9f5';
}
.fa-fire:before {
  content: '\e9f6';
}
.fa-firefox:before {
  content: '\e9f7';
}
.fa-flag-checkered:before {
  content: '\e9f8';
}
.fa-flag-o:before {
  content: '\e9f9';
}
.fa-flag:before {
  content: '\e9fa';
}
.fa-flash:before {
  content: '\e9fb';
}
.fa-flask:before {
  content: '\e9fc';
}
.fa-flickr:before {
  content: '\e9fd';
}
.fa-floppy-o:before {
  content: '\e9fe';
}
.fa-folder-o:before {
  content: '\e9ff';
}
.fa-folder-open-o:before {
  content: '\ea00';
}
.fa-folder-open:before {
  content: '\ea01';
}
.fa-folder:before {
  content: '\ea02';
}
.fa-font:before {
  content: '\ea03';
}
.fa-fonticons:before {
  content: '\ea04';
}
.fa-forumbee:before {
  content: '\ea05';
}
.fa-forward:before {
  content: '\ea06';
}
.fa-foursquare:before {
  content: '\ea07';
}
.fa-frown-o:before {
  content: '\ea08';
}
.fa-futbol-o:before {
  content: '\ea09';
}
.fa-gamepad:before {
  content: '\ea0a';
}
.fa-gavel:before {
  content: '\ea0b';
}
.fa-gbp:before {
  content: '\ea0c';
}
.fa-gear:before {
  content: '\ea0d';
}
.fa-gears:before {
  content: '\ea0e';
}
.fa-genderless:before {
  content: '\ea0f';
}
.fa-get-pocket:before {
  content: '\ea10';
}
.fa-gg-circle:before {
  content: '\ea11';
}
.fa-gg:before {
  content: '\ea12';
}
.fa-gift:before {
  content: '\ea13';
}
.fa-git-square:before {
  content: '\ea14';
}
.fa-git:before {
  content: '\ea15';
}
.fa-github-alt:before {
  content: '\ea16';
}
.fa-github-square:before {
  content: '\ea17';
}
.fa-github:before {
  content: '\ea18';
}
.fa-glass:before {
  content: '\ea19';
}
.fa-globe:before {
  content: '\ea1a';
}
.fa-google-plus-square:before {
  content: '\ea1b';
}
.fa-google-plus:before {
  content: '\ea1c';
}
.fa-google-wallet:before {
  content: '\ea1d';
}
.fa-google:before {
  content: '\ea1e';
}
.fa-graduation-cap:before {
  content: '\ea1f';
}
.fa-gratipay:before {
  content: '\ea20';
}
.fa-group:before {
  content: '\ea21';
}
.fa-h-square:before {
  content: '\ea22';
}
.fa-hacker-news:before {
  content: '\ea23';
}
.fa-hand-grab-o:before {
  content: '\ea24';
}
.fa-hand-lizard-o:before {
  content: '\ea25';
}
.fa-hand-o-down:before {
  content: '\ea26';
}
.fa-hand-o-left:before {
  content: '\ea27';
}
.fa-hand-o-right:before {
  content: '\ea28';
}
.fa-hand-o-up:before {
  content: '\ea29';
}
.fa-hand-paper-o:before {
  content: '\ea2a';
}
.fa-hand-peace-o:before {
  content: '\ea2b';
}
.fa-hand-pointer-o:before {
  content: '\ea2c';
}
.fa-hand-rock-o:before {
  content: '\ea2d';
}
.fa-hand-scissors-o:before {
  content: '\ea2e';
}
.fa-hand-spock-o:before {
  content: '\ea2f';
}
.fa-hand-stop-o:before {
  content: '\ea30';
}
.fa-hdd-o:before {
  content: '\ea31';
}
.fa-header:before {
  content: '\ea32';
}
.fa-headphones:before {
  content: '\ea33';
}
.fa-heart-o:before {
  content: '\ea34';
}
.fa-heart:before {
  content: '\ea35';
}
.fa-heartbeat:before {
  content: '\ea36';
}
.fa-history:before {
  content: '\ea37';
}
.fa-home:before {
  content: '\ea38';
}
.fa-hospital-o:before {
  content: '\ea39';
}
.fa-hotel:before {
  content: '\ea3a';
}
.fa-hourglass-1:before {
  content: '\ea3b';
}
.fa-hourglass-2:before {
  content: '\ea3c';
}
.fa-hourglass-3:before {
  content: '\ea3d';
}
.fa-hourglass-end:before {
  content: '\ea3e';
}
.fa-hourglass-half:before {
  content: '\ea3f';
}
.fa-hourglass-o:before {
  content: '\ea40';
}
.fa-hourglass-start:before {
  content: '\ea41';
}
.fa-hourglass:before {
  content: '\ea42';
}
.fa-houzz:before {
  content: '\ea43';
}
.fa-html5:before {
  content: '\ea44';
}
.fa-i-cursor:before {
  content: '\ea45';
}
.fa-ils:before {
  content: '\ea46';
}
.fa-image:before {
  content: '\ea47';
}
.fa-inbox:before {
  content: '\ea48';
}
.fa-indent:before {
  content: '\ea49';
}
.fa-industry:before {
  content: '\ea4a';
}
.fa-info-circle:before {
  content: '\ea4b';
}
.fa-info:before {
  content: '\ea4c';
}
.fa-inr:before {
  content: '\ea4d';
}
.fa-instagram:before {
  content: '\ea4e';
}
.fa-institution:before {
  content: '\ea4f';
}
.fa-internet-explorer:before {
  content: '\ea50';
}
.fa-ioxhost:before {
  content: '\ea51';
}
.fa-italic:before {
  content: '\ea52';
}
.fa-joomla:before {
  content: '\ea53';
}
.fa-jpy:before {
  content: '\ea54';
}
.fa-jsfiddle:before {
  content: '\ea55';
}
.fa-key:before {
  content: '\ea56';
}
.fa-keyboard-o:before {
  content: '\ea57';
}
.fa-krw:before {
  content: '\ea58';
}
.fa-language:before {
  content: '\ea59';
}
.fa-laptop:before {
  content: '\ea5a';
}
.fa-lastfm-square:before {
  content: '\ea5b';
}
.fa-lastfm:before {
  content: '\ea5c';
}
.fa-leaf:before {
  content: '\ea5d';
}
.fa-leanpub:before {
  content: '\ea5e';
}
.fa-legal:before {
  content: '\ea5f';
}
.fa-lemon-o:before {
  content: '\ea60';
}
.fa-level-down:before {
  content: '\ea61';
}
.fa-level-up:before {
  content: '\ea62';
}
.fa-life-bouy:before {
  content: '\ea63';
}
.fa-life-buoy:before {
  content: '\ea64';
}
.fa-life-ring:before {
  content: '\ea65';
}
.fa-life-saver:before {
  content: '\ea66';
}
.fa-lightbulb-o:before {
  content: '\ea67';
}
.fa-line-chart:before {
  content: '\ea68';
}
.fa-link:before {
  content: '\ea69';
}
.fa-linkedin-square:before {
  content: '\ea6a';
}
.fa-linkedin:before {
  content: '\ea6b';
}
.fa-linux:before {
  content: '\ea6c';
}
.fa-list-alt:before {
  content: '\ea6d';
}
.fa-list-ol:before {
  content: '\ea6e';
}
.fa-list-ul:before {
  content: '\ea6f';
}
.fa-list:before {
  content: '\ea70';
}
.fa-location-arrow:before {
  content: '\ea71';
}
.fa-lock:before {
  content: '\ea72';
}
.fa-long-arrow-down:before {
  content: '\ea73';
}
.fa-long-arrow-left:before {
  content: '\ea74';
}
.fa-long-arrow-right:before {
  content: '\ea75';
}
.fa-long-arrow-up:before {
  content: '\ea76';
}
.fa-magic:before {
  content: '\ea77';
}
.fa-magnet:before {
  content: '\ea78';
}
.fa-mail-forward:before {
  content: '\ea79';
}
.fa-mail-reply-all:before {
  content: '\ea7a';
}
.fa-mail-reply:before {
  content: '\ea7b';
}
.fa-male:before {
  content: '\ea7c';
}
.fa-map-marker:before {
  content: '\ea7d';
}
.fa-map-o:before {
  content: '\ea7e';
}
.fa-map-pin:before {
  content: '\ea7f';
}
.fa-map-signs:before {
  content: '\ea80';
}
.fa-map:before {
  content: '\ea81';
}
.fa-mars-double:before {
  content: '\ea82';
}
.fa-mars-stroke-h:before {
  content: '\ea83';
}
.fa-mars-stroke-v:before {
  content: '\ea84';
}
.fa-mars-stroke:before {
  content: '\ea85';
}
.fa-mars:before {
  content: '\ea86';
}
.fa-maxcdn:before {
  content: '\ea87';
}
.fa-meanpath:before {
  content: '\ea88';
}
.fa-medium:before {
  content: '\ea89';
}
.fa-medkit:before {
  content: '\ea8a';
}
.fa-meh-o:before {
  content: '\ea8b';
}
.fa-mercury:before {
  content: '\ea8c';
}
.fa-microphone-slash:before {
  content: '\ea8d';
}
.fa-microphone:before {
  content: '\ea8e';
}
.fa-minus-circle:before {
  content: '\ea8f';
}
.fa-minus-square-o:before {
  content: '\ea90';
}
.fa-minus-square:before {
  content: '\ea91';
}
.fa-minus:before {
  content: '\ea92';
}
.fa-mobile-phone:before {
  content: '\ea93';
}
.fa-mobile:before {
  content: '\ea94';
}
.fa-money:before {
  content: '\ea95';
}
.fa-moon-o:before {
  content: '\ea96';
}
.fa-mortar-board:before {
  content: '\ea97';
}
.fa-motorcycle:before {
  content: '\ea98';
}
.fa-mouse-pointer:before {
  content: '\ea99';
}
.fa-music:before {
  content: '\ea9a';
}
.fa-navicon:before {
  content: '\ea9b';
}
.fa-neuter:before {
  content: '\ea9c';
}
.fa-newspaper-o:before {
  content: '\ea9d';
}
.fa-object-group:before {
  content: '\ea9e';
}
.fa-object-ungroup:before {
  content: '\ea9f';
}
.fa-odnoklassniki-square:before {
  content: '\eaa0';
}
.fa-odnoklassniki:before {
  content: '\eaa1';
}
.fa-opencart:before {
  content: '\eaa2';
}
.fa-openid:before {
  content: '\eaa3';
}
.fa-opera:before {
  content: '\eaa4';
}
.fa-optin-monster:before {
  content: '\eaa5';
}
.fa-outdent:before {
  content: '\eaa6';
}
.fa-pagelines:before {
  content: '\eaa7';
}
.fa-paint-brush:before {
  content: '\eaa8';
}
.fa-paper-plane-o:before {
  content: '\eaa9';
}
.fa-paper-plane:before {
  content: '\eaaa';
}
.fa-paperclip:before {
  content: '\eaab';
}
.fa-paragraph:before {
  content: '\eaac';
}
.fa-paste:before {
  content: '\eaad';
}
.fa-pause:before {
  content: '\eaae';
}
.fa-paw:before {
  content: '\eaaf';
}
.fa-paypal:before {
  content: '\eab0';
}
.fa-pencil-square-o:before {
  content: '\eab1';
}
.fa-pencil-square:before {
  content: '\eab2';
}
.fa-pencil:before {
  content: '\eab3';
}
.fa-phone-square:before {
  content: '\eab4';
}
.fa-phone:before {
  content: '\eab5';
}
.fa-photo:before {
  content: '\eab6';
}
.fa-picture-o:before {
  content: '\eab7';
}
.fa-pie-chart:before {
  content: '\eab8';
}
.fa-pied-piper-alt:before {
  content: '\eab9';
}
.fa-pied-piper:before {
  content: '\eaba';
}
.fa-pinterest-p:before {
  content: '\eabb';
}
.fa-pinterest-square:before {
  content: '\eabc';
}
.fa-pinterest:before {
  content: '\eabd';
}
.fa-plane:before {
  content: '\eabe';
}
.fa-play-circle-o:before {
  content: '\eabf';
}
.fa-play-circle:before {
  content: '\eac0';
}
.fa-play:before {
  content: '\eac1';
}
.fa-plug:before {
  content: '\eac2';
}
.fa-plus-circle:before {
  content: '\eac3';
}
.fa-plus-square-o:before {
  content: '\eac4';
}
.fa-plus-square:before {
  content: '\eac5';
}
.fa-plus:before {
  content: '\eac6';
}
.fa-power-off:before {
  content: '\eac7';
}
.fa-print:before {
  content: '\eac8';
}
.fa-puzzle-piece:before {
  content: '\eac9';
}
.fa-qq:before {
  content: '\eaca';
}
.fa-qrcode:before {
  content: '\eacb';
}
.fa-question-circle:before {
  content: '\eacc';
}
.fa-question:before {
  content: '\eacd';
}
.fa-quote-left:before {
  content: '\eace';
}
.fa-quote-right:before {
  content: '\eacf';
}
.fa-ra:before {
  content: '\ead0';
}
.fa-random:before {
  content: '\ead1';
}
.fa-rebel:before {
  content: '\ead2';
}
.fa-recycle:before {
  content: '\ead3';
}
.fa-reddit-square:before {
  content: '\ead4';
}
.fa-reddit:before {
  content: '\ead5';
}
.fa-refresh:before {
  content: '\ead6';
}
.fa-registered:before {
  content: '\ead7';
}
.fa-renren:before {
  content: '\ead8';
}
.fa-reorder:before {
  content: '\ead9';
}
.fa-repeat:before {
  content: '\eada';
}
.fa-reply-all:before {
  content: '\eadb';
}
.fa-reply:before {
  content: '\eadc';
}
.fa-retweet:before {
  content: '\eadd';
}
.fa-rmb:before {
  content: '\eade';
}
.fa-road:before {
  content: '\eadf';
}
.fa-rocket:before {
  content: '\eae0';
}
.fa-rotate-left:before {
  content: '\eae1';
}
.fa-rotate-right:before {
  content: '\eae2';
}
.fa-rouble:before {
  content: '\eae3';
}
.fa-rss-square:before {
  content: '\eae4';
}
.fa-rss:before {
  content: '\eae5';
}
.fa-rub:before {
  content: '\eae6';
}
.fa-ruble:before {
  content: '\eae7';
}
.fa-rupee:before {
  content: '\eae8';
}
.fa-safari:before {
  content: '\eae9';
}
.fa-save:before {
  content: '\eaea';
}
.fa-scissors:before {
  content: '\eaeb';
}
.fa-search-minus:before {
  content: '\eaec';
}
.fa-search-plus:before {
  content: '\eaed';
}
.fa-search:before {
  content: '\eaee';
}
.fa-sellsy:before {
  content: '\eaef';
}
.fa-server:before {
  content: '\eaf0';
}
.fa-share-alt-square:before {
  content: '\eaf1';
}
.fa-share-alt:before {
  content: '\eaf2';
}
.fa-share-square-o:before {
  content: '\eaf3';
}
.fa-share-square:before {
  content: '\eaf4';
}
.fa-share:before {
  content: '\eaf5';
}
.fa-shekel:before {
  content: '\eaf6';
}
.fa-sheqel:before {
  content: '\eaf7';
}
.fa-shield:before {
  content: '\eaf8';
}
.fa-ship:before {
  content: '\eaf9';
}
.fa-shirtsinbulk:before {
  content: '\eafa';
}
.fa-shopping-cart:before {
  content: '\eafb';
}
.fa-sign-in:before {
  content: '\eafc';
}
.fa-sign-out:before {
  content: '\eafd';
}
.fa-signal:before {
  content: '\eafe';
}
.fa-simplybuilt:before {
  content: '\eaff';
}
.fa-sitemap:before {
  content: '\eb00';
}
.fa-skyatlas:before {
  content: '\eb01';
}
.fa-skype:before {
  content: '\eb02';
}
.fa-slack:before {
  content: '\eb03';
}
.fa-sliders:before {
  content: '\eb04';
}
.fa-slideshare:before {
  content: '\eb05';
}
.fa-smile-o:before {
  content: '\eb06';
}
.fa-sort-alpha-asc:before {
  content: '\eb07';
}
.fa-sort-alpha-desc:before {
  content: '\eb08';
}
.fa-sort-amount-asc:before {
  content: '\eb09';
}
.fa-sort-amount-desc:before {
  content: '\eb0a';
}
.fa-sort-asc:before {
  content: '\eb0b';
}
.fa-sort-desc:before {
  content: '\eb0c';
}
.fa-sort-numeric-asc:before {
  content: '\eb0d';
}
.fa-sort-numeric-desc:before {
  content: '\eb0e';
}
.fa-sort:before {
  content: '\eb0f';
}
.fa-soundcloud:before {
  content: '\eb10';
}
.fa-space-shuttle:before {
  content: '\eb11';
}
.fa-spinner:before {
  content: '\eb12';
}
.fa-spoon:before {
  content: '\eb13';
}
.fa-spotify:before {
  content: '\eb14';
}
.fa-square-o:before {
  content: '\eb15';
}
.fa-square:before {
  content: '\eb16';
}
.fa-stack-exchange:before {
  content: '\eb17';
}
.fa-stack-overflow:before {
  content: '\eb18';
}
.fa-star-half-o:before {
  content: '\eb19';
}
.fa-star-half:before {
  content: '\eb1a';
}
.fa-star-o:before {
  content: '\eb1b';
}
.fa-star:before {
  content: '\eb1c';
}
.fa-steam-square:before {
  content: '\eb1d';
}
.fa-steam:before {
  content: '\eb1e';
}
.fa-step-backward:before {
  content: '\eb1f';
}
.fa-step-forward:before {
  content: '\eb20';
}
.fa-stethoscope:before {
  content: '\eb21';
}
.fa-sticky-note-o:before {
  content: '\eb22';
}
.fa-sticky-note:before {
  content: '\eb23';
}
.fa-stop:before {
  content: '\eb24';
}
.fa-street-view:before {
  content: '\eb25';
}
.fa-strikethrough:before {
  content: '\eb26';
}
.fa-stumbleupon-circle:before {
  content: '\eb27';
}
.fa-stumbleupon:before {
  content: '\eb28';
}
.fa-subscript:before {
  content: '\eb29';
}
.fa-subway:before {
  content: '\eb2a';
}
.fa-suitcase:before {
  content: '\eb2b';
}
.fa-sun-o:before {
  content: '\eb2c';
}
.fa-superscript:before {
  content: '\eb2d';
}
.fa-table:before {
  content: '\eb2e';
}
.fa-tablet:before {
  content: '\eb2f';
}
.fa-tachometer:before {
  content: '\eb30';
}
.fa-tag:before {
  content: '\eb31';
}
.fa-tags:before {
  content: '\eb32';
}
.fa-tasks:before {
  content: '\eb33';
}
.fa-taxi:before {
  content: '\eb34';
}
.fa-television:before {
  content: '\eb35';
}
.fa-tencent-weibo:before {
  content: '\eb36';
}
.fa-terminal:before {
  content: '\eb37';
}
.fa-text-height:before {
  content: '\eb38';
}
.fa-text-width:before {
  content: '\eb39';
}
.fa-th-large:before {
  content: '\eb3a';
}
.fa-th-list:before {
  content: '\eb3b';
}
.fa-th:before {
  content: '\eb3c';
}
.fa-thumb-tack:before {
  content: '\eb3d';
}
.fa-thumbs-down:before {
  content: '\eb3e';
}
.fa-thumbs-o-down:before {
  content: '\eb3f';
}
.fa-thumbs-o-up:before {
  content: '\eb40';
}
.fa-thumbs-up:before {
  content: '\eb41';
}
.fa-ticket:before {
  content: '\eb42';
}
.fa-times-circle-o:before {
  content: '\eb43';
}
.fa-times-circle:before {
  content: '\eb44';
}
.fa-times:before {
  content: '\eb45';
}
.fa-tint:before {
  content: '\eb46';
}
.fa-toggle-off:before {
  content: '\eb47';
}
.fa-toggle-on:before {
  content: '\eb48';
}
.fa-trademark:before {
  content: '\eb49';
}
.fa-train:before {
  content: '\eb4a';
}
.fa-transgender-alt:before {
  content: '\eb4b';
}
.fa-transgender:before {
  content: '\eb4c';
}
.fa-trash-o:before {
  content: '\eb4d';
}
.fa-trash:before {
  content: '\eb4e';
}
.fa-tree:before {
  content: '\eb4f';
}
.fa-trello:before {
  content: '\eb50';
}
.fa-tripadvisor:before {
  content: '\eb51';
}
.fa-trophy:before {
  content: '\eb52';
}
.fa-truck:before {
  content: '\eb53';
}
.fa-try:before {
  content: '\eb54';
}
.fa-tty:before {
  content: '\eb55';
}
.fa-tumblr-square:before {
  content: '\eb56';
}
.fa-tumblr:before {
  content: '\eb57';
}
.fa-turkish-lira:before {
  content: '\eb58';
}
.fa-twitch:before {
  content: '\eb59';
}
.fa-twitter-square:before {
  content: '\eb5a';
}
.fa-twitter:before {
  content: '\eb5b';
}
.fa-umbrella:before {
  content: '\eb5c';
}
.fa-underline:before {
  content: '\eb5d';
}
.fa-undo:before {
  content: '\eb5e';
}
.fa-university:before {
  content: '\eb5f';
}
.fa-unlink:before {
  content: '\eb60';
}
.fa-unlock-alt:before {
  content: '\eb61';
}
.fa-unlock:before {
  content: '\eb62';
}
.fa-upload:before {
  content: '\eb63';
}
.fa-usd:before {
  content: '\eb64';
}
.fa-user-md:before {
  content: '\eb65';
}
.fa-user-plus:before {
  content: '\eb66';
}
.fa-user-secret:before {
  content: '\eb67';
}
.fa-user-times:before {
  content: '\eb68';
}
.fa-user:before {
  content: '\eb69';
}
.fa-users:before {
  content: '\eb6a';
}
.fa-venus-double:before {
  content: '\eb6b';
}
.fa-venus-mars:before {
  content: '\eb6c';
}
.fa-venus:before {
  content: '\eb6d';
}
.fa-viacoin:before {
  content: '\eb6e';
}
.fa-video-camera:before {
  content: '\eb6f';
}
.fa-vimeo-square:before {
  content: '\eb70';
}
.fa-vimeo:before {
  content: '\eb71';
}
.fa-vine:before {
  content: '\eb72';
}
.fa-vk:before {
  content: '\eb73';
}
.fa-volume-down:before {
  content: '\eb74';
}
.fa-volume-off:before {
  content: '\eb75';
}
.fa-volume-up:before {
  content: '\eb76';
}
.fa-warning:before {
  content: '\eb77';
}
.fa-wechat:before {
  content: '\eb78';
}
.fa-weibo:before {
  content: '\eb79';
}
.fa-weixin:before {
  content: '\eb7a';
}
.fa-whatsapp:before {
  content: '\eb7b';
}
.fa-wheelchair:before {
  content: '\eb7c';
}
.fa-wifi:before {
  content: '\eb7d';
}
.fa-wikipedia-w:before {
  content: '\eb7e';
}
.fa-windows:before {
  content: '\eb7f';
}
.fa-won:before {
  content: '\eb80';
}
.fa-wordpress:before {
  content: '\eb81';
}
.fa-wrench:before {
  content: '\eb82';
}
.fa-xing-square:before {
  content: '\eb83';
}
.fa-xing:before {
  content: '\eb84';
}
.fa-y-combinator-square:before {
  content: '\eb85';
}
.fa-y-combinator:before {
  content: '\eb86';
}
.fa-yahoo:before {
  content: '\eb87';
}
.fa-yc-square:before {
  content: '\eb88';
}
.fa-yc:before {
  content: '\eb89';
}
.fa-yelp:before {
  content: '\eb8a';
}
.fa-yen:before {
  content: '\eb8b';
}
.fa-youtube-play:before {
  content: '\eb8c';
}
.fa-youtube-square:before {
  content: '\eb8d';
}
.fa-youtube:before {
  content: '\eb8e';
}
