//Add global
@import 'themes/theme-fa.scss';
@import 'themes/css-variables.scss';
@import './app/bootstrap.scss';

@import '../../../node_modules/ngx-toastr/toastr.css';
@import '../../../node_modules/@swimlane/ngx-datatable/index.css';
@import '../../../node_modules/@swimlane/ngx-datatable/themes/bootstrap.css';
@import '../../../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import './app/ngx-datatable.scss';
@import './icons/icomoon.scss';
@import '@phosphor-icons/web/light';
@import '@phosphor-icons/web/thin';
@import '@phosphor-icons/web/fill';
@import '../../../node_modules/@videogular/ngx-videogular/fonts/videogular.css';

.toast-container {
    top: 9rem;

    > .toast-info {
        background-color: $brand-info;
    }

    > .toast-error {
        background-color: $brand-danger;
    }

    > .toast-warning {
        background-color: $brand-warning;
    }

    > .toast-success {
        background-color: $brand-success;
    }
}

.collapsed-nav {
    width: 0 !important;

    & > .aside-inner {
        width: 0 !important;
    }
}

.collapsed-nav-right {
    right: -500px !important;
}

.collapsed-nav-left {
    left: -500px !important;
}

li.divider {
    border-top: 1px solid $brand-text;
}

.hoverable:hover {
    cursor: pointer !important;
    color: $brand-info; // brand info = active A
    text-decoration: underline;
}

.nav-heading-item {
    font-weight: bold !important;
    font-size: 11pt !important;
    padding-left: 0 !important;
}

.input-group > .input-group-addon,
.list-group-item,
.progress,
ul.pager > li > a {
    border-radius: 0% !important;
}

.input-group-btn {
    .btn {
        padding: 12px 15px;
        border-top-left-radius: 0% !important;
        border-bottom-left-radius: 0% !important;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-width: 0px;
    }
}

.input-group-addon {
    border: 1px solid #ddd;
    border-right: 0;
}

.page-count {
    color: $brand-primary !important;
}

ul.pager {
    > li {
        > a {
            border: none;
            background-color: transparent;
            color: $brand-primary !important;

            > i {
                color: $brand-primary;
            }
        }

        &.disabled {
            > a {
                border: none;
                background-color: transparent;
                color: $gray-dark !important;

                > i {
                    color: $gray-dark;
                }
            }
        }

        &.active,
        &:not(.disabled):hover {
            > a {
                border: none;
                background-color: transparent !important;
                color: $brand-text !important;

                > i {
                    color: #ffffff;
                }
            }
        }
    }
}

.input-group > .input-group-addon > i {
    width: 1em;
}

input[type='text'],
input[type='number'],
input[type='email'] {
    &.ng-invalid.ng-touched {
        border-color: $brand-danger;
    }
}

app-form-datepicker.ng-invalid.ng-touched app-datepicker input {
    border-color: $brand-danger;
}

.btn.btn-small {
    padding: 0;
    height: 2.5rem;
    width: 2.5rem;
}

.btn.btn-medium {
    padding: 9px 11px;
}

.btn-inverted-danger {
    padding: 0.5em 1em !important;

    &.no-background {
        background-color: transparent;
    }

    &:hover {
        background-color: $brand-danger;
        color: white;
    }
}

label.for-typeahead,
.label.for-draganddrop {
    display: block;
}

.label.for-draganddrop {
    padding: 4pt 5pt;
    text-align: left;
    font-size: 10pt;
    white-space: normal;
    overflow: hidden;
    margin: 1px;

    &:hover {
        cursor: all-scroll;
    }
}

.topnav-item {
    text-align: center;

    .nav-title {
        display: block;
        font-size: 0.75em;
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

timepicker .form-group {
    margin-bottom: 0;
}

.backdrop .panel {
    position: fixed !important;
    width: 40% !important;
    height: auto !important;
    margin: 10% 30% !important;
    max-height: 60% !important;
    display: flex;
    flex-direction: column;

    .panel-heading,
    .panel-footer {
        flex: 0 0 auto;
    }

    .panel-body {
        flex: 0 1 auto;
        overflow: auto;
        max-height: 50vh;
    }
}

.facet-nav {
    padding: 1.5em 1.40625em;
    border: 1px solid #e3e3e3;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .panel-group {
        margin-bottom: 0;
    }

    .panel {
        border: none;
        box-shadow: none;

        .filter-panel {
            border: none;

            .panel-heading {
                padding: 1.3125em 0 0;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: bold;
                background-color: white;
                color: $gray-darker;

                .toggle-icon-wrapper {
                    position: relative;
                    padding-right: 25px;

                    i.expand-icon {
                        position: absolute;
                        top: 0;
                        right: 0;
                        color: $gray-dark;
                    }
                }
            }

            .panel-collapse {
                .panel-body {
                    padding: 0;
                    border: none;

                    .filter-group {
                        margin-bottom: 1.5em;

                        li {
                            padding: 0.375em 0;
                        }

                        label {
                            margin: 0;
                        }

                        .form-group {
                            margin: 0;
                        }

                        .label-option {
                            position: relative;
                            padding-left: 1.40625em;
                            line-height: 1.4em;
                            font-weight: 400;

                            input[type='checkbox'] {
                                position: absolute;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .btn {
        width: 100%;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.toggle-section {
    .panel-group {
        margin-bottom: 0;
    }

    .panel {
        border: none;
        box-shadow: none;
    }

    .panel-heading {
        font-size: 1.414em;
        line-height: 1.54703;
        margin: 0;
        padding-top: 0.92822em;
        padding-bottom: 0.30941em;
        font-weight: 700;
        text-rendering: optimizelegibility;
        color: $brand-primary-light;

        .section-toggle {
            margin: 0;
        }
    }

    .panel-heading + .panel-collapse > .panel-body {
        padding-top: 0;
        padding-bottom: 0;
        border: none;
    }

    &:last-child {
        padding-bottom: 20px;
    }
}

// Zindex raised to enable the datepicker on dialogs
bs-datepicker-container {
    z-index: 9999999;
}

hr {
    width: 100%;
}

.text-golden {
    color: #ffd700;
}

.star {
    font-size: 16pt;
}

$fixedsessionColor: #76bce9;
$opensessionColor: #706a6a;
$opensharedsessionColor: #e0b600;
$programFixedsessionColor: #76bce9;
$programOpensessionColor: #e83f4b;
$learningentityColor: #393c50;
$examentityColor: #e83f4b;
$locationCodeColor: #e8833f;

$fixedsessionColor-light: #ddf1ff;
$opensessionColor-light: #f0ebeb;
$opensharedsessionColor-light: #f7ecbe;
$programFixedsessionColor-light: #ddf1ff;
$programOpensessionColor-light: #f3d6d9;
$learningentityColor-light: #393c50;
$examentityColor-light: #f2c8cd;
$locationCodeColor-light: #e8833f;

.p-dialog {
    .tag-trajectory-type {
        vertical-align: text-top;
        font-size: 14px;
        font-weight: 700 !important;
        margin-right: 5px;
        color: inherit !important;
        font-style: italic;
    }
    &.panel-program-opensession {
        border: none;

        .p-dialog-header-icon {
            color: #fff !important;
        }
        .p-dialog-header {
            background-color: $programOpensessionColor;
            border-color: $programOpensessionColor;
            color: #fff;
        }
    }

    &.panel-opensession {
        border: none;

        .p-dialog-header-icon {
            color: #fff !important;
        }
        .p-dialog-header {
            background-color: $opensessionColor;
            border-color: $opensessionColor;
            color: #fff;
        }
    }

    &.panel-opensharedsession {
        border: none;

        .p-dialog-header-icon {
            color: #fff !important;
        }
        .p-dialog-header {
            background-color: $opensharedsessionColor;
            border-color: $opensharedsessionColor;
            color: #fff;
        }
    }

    &.panel-program-fixedsession {
        border: none;

        .p-dialog-header-icon {
            color: #fff !important;
        }
        .p-dialog-header {
            background-color: $programFixedsessionColor;
            border-color: $programFixedsessionColor;
            color: #fff;
        }
    }

    &.panel-fixedsession {
        border: none;

        .p-dialog-header-icon {
            color: #fff !important;
        }
        .p-dialog-header {
            background-color: $fixedsessionColor;
            border-color: $fixedsessionColor;
            color: #fff;
        }
    }
}

.alert {
    &.alert-program-opensession {
        background-color: $programOpensessionColor;
        border-color: $programOpensessionColor;
    }

    &.alert-opensession {
        background-color: $opensessionColor;
        border-color: $opensessionColor;
    }

    &.alert-opensharedsession {
        background-color: $opensharedsessionColor;
        border-color: $opensharedsessionColor;
    }

    &.alert-program-fixedsession {
        background-color: $programFixedsessionColor;
        border-color: $programFixedsessionColor;
    }

    &.alert-fixedsession {
        background-color: $fixedsessionColor;
        border-color: $fixedsessionColor;
    }
}

.label {
    &.label-learningentity {
        background-color: $learningentityColor;
    }

    &.label-examentity {
        background-color: $examentityColor;
    }

    &.label-fixedsession {
        background-color: $fixedsessionColor-light;
        border-color: $fixedsessionColor;
    }

    &.label-opensession {
        background-color: $opensessionColor-light;
        border-color: $opensessionColor;
    }

    &.label-opensharedsession {
        background-color: $opensharedsessionColor-light;
        border-color: $opensharedsessionColor;
    }

    &.label-program-fixedsession {
        background-color: $programFixedsessionColor-light;
        border-color: $programFixedsessionColor;
    }

    &.label-program-opensession {
        background-color: $programOpensessionColor-light;
        border-color: $programOpensessionColor;
    }

    &.label-location-code {
        background-color: $locationCodeColor;
    }

    &.label-location {
        padding: 2px 5px;
        font-size: 9px;
    }

    &.label-square {
        border-radius: 10px;
    }
    &.label-session {
        color: $gray-darker;
        font-size: 12px;
        line-height: 13px;
        padding: 7px;
        border-left-width: 3px;
        border-left-style: solid;
    }
    &.label-entity-type {
        white-space: normal;
        text-align: left;
        font-size: 9px;
        padding: 3px 8px;
    }
    &.border-only {
        &.label-default {
            background-color: #eee;
            border: 1px solid #777;
            color: #000;
        }
    }
    .label-capacity {
        padding-top: 6px;
        color: #7c7e81;
        display: table-cell;
        font-size: 11px;
    }
}

.tabset > div > ul > li > a > span {
    white-space: normal !important;
}

.btn.btn-small {
    padding: 0 !important;
}

.btn.btn-move {
    width: 50px;
    margin-bottom: 5px;
}

.wrap-text {
    white-space: nowrap;
}

.tooltip {
    pointer-events: none;
}

[hidden] {
    display: none !important;
}

.datatable-body-row {
    .datatable-row-left {
        background-color: white;
    }

    .datatable-row-right {
        background-color: white;
    }
}

.fixedheader {
    .datatable-header-cell-template-wrap {
        height: 45px !important;
    }
}

.line-through {
    text-decoration: line-through;
}

// migrating to bootstrap 4

//Images
.img-responsive {
    @extend .img-fluid;
}

.img-rounded {
    @extend .rounded;
}

.img-circle {
    @extend .rounded-circle;
}

//List groups
.list-group-item {
    @extend .list-group-item-action;
}

//FORMS
.form-inline {
    @extend .d-flex;
    @extend .flex-row;
    @extend .align-items-center;
    @extend .flex-wrap;
}
.form-inline {
    @extend .d-flex;
    @extend .flex-row;
    @extend .align-items-center;
    @extend .flex-wrap;
}

.form-group {
    @extend .mb-3;
}

.form-inline .form-group {
    display: block !important;
}
.form-row {
    @extend .row;
}

//Columns

@for $i from 1 through 11 {
    .col-sm-offset-#{$i} {
        @extend .offset-sm-#{$i};
    }
    .col-md-offset-#{$i} {
        @extend .offset-md-#{$i};
    }
    .col-lg-offset-#{$i} {
        @extend .offset-lg-#{$i};
    }
}

//card

.panel {
    @extend .card;
}

.panel-heading {
    @extend .card-header;
}
.panel-title {
    @extend .card-title;
}

.panel-body {
    @extend .card-body;
}
.panel-footer {
    @extend .card-footer;
}
.well {
    @extend .card-body;
}

//Utilities
.text-left {
    @extend .text-start;
}

.text-right {
    @extend .text-end;
}

@for $i from 0 through 5 {
    .ml-#{$i} {
        @extend .ms-#{$i};
    }
    .mr-#{$i} {
        @extend .me-#{$i};
    }
    .pl-#{$i} {
        @extend .ps-#{$i};
    }
    .pr-#{$i} {
        @extend .pe-#{$i};
    }
}

.label {
    @extend .badge;
}

//TEMPORARY - label background
.label-primary {
    background-color: $label-primary-bg;
}

.label-success {
    background-color: $label-success-bg;
}

.label-info {
    background-color: $label-info-bg;
}

.label-warning {
    background-color: $label-warning-bg;
}

.label-danger {
    background-color: $label-danger-bg;
}

// Tag variation
// -------------------------
.tag-sm {
    @extend .badge;
    @extend .rounded-pill;

    i {
        font-size: 12px;
        vertical-align: -2px;
    }
    svg-icon {
        width: 11px;
        height: 11px;

        svg {
            vertical-align: -15%;
        }
    }
}

.tag-pill {
    @extend .badge;
    @extend .rounded-pill;
    font-size: 11px;
    font-weight: 400 !important;
    padding: 3px 10px 3px 10px;
    i {
        font-size: 18px;
        vertical-align: -4px;
    }
    svg-icon {
        width: 19px;
        height: 12px;

        svg {
            vertical-align: -15%;
        }
    }
}

.tag-score {
    @extend .badge;
    font-size: 10px;
    font-weight: 400 !important;
    padding: 6px 9px 4px 9px;
    border-radius: 5px;
}

// Dashboard cards
// -------------------------
.card-dashboard {
    @extend .shadow;
}
.card-small {
    @extend .col-lg-3;
    @extend .col-md-4;
    @extend .col-sm-12;
}
.card-medium {
    @extend .col-lg-6;
    @extend .col-md-6;
    @extend .col-sm-12;
}
.card-large {
    @extend .col-lg-9;
    @extend .col-md-12;
    @extend .col-sm-12;
}
.medium-grow {
    @extend .col-lg-6;
    @extend .col-md-6;
    @extend .col-sm-12;
}
.large-grow {
    @extend .col-lg-12;
    @extend .col-md-12;
    @extend .col-sm-12;
}
